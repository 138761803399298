import { twMerge } from 'tailwind-merge';

import { PoweredByFooter } from '../PoweredByFooter';

import type { PropsWithChildren } from 'react';
import type { PLGFooterTypes } from '../PoweredByFooter';

export interface MainLayoutProps {
  children: PropsWithChildren<React.ReactNode>;
  className?: string;
  actions?: PropsWithChildren<React.ReactNode>;
  withBackground?: boolean;
  actionsClassName?: string;
  actionSticky?: boolean;
  plg?: {
    type: PLGFooterTypes;
    referralCode?: string;
    text?: string;
  };
}

export const Main = ({
  children,
  className,
  actions,
  actionsClassName,
  plg,
}: MainLayoutProps) => {
  return (
    <div
      className={twMerge(
        'w-full flex-grow flex flex-col bg-background',
        className
      )}
      data-testid="main-layout"
    >
      <main className="grow mx-auto mt-6 max-w-3xl w-full relative">
        <div className="px-4 @sm-screen:px-6 @md-screen:px-10 max-w-3xl w-full h-full max-h-fit flex flex-col">
          {children}
        </div>
      </main>
      <div className={actions && plg && 'w-full'}>
        {actions && (
          <div
            className={twMerge(
              'py-4 border-t border-footer-border bg-footer-background',
              actionsClassName
            )}
          >
            <div className={twMerge('max-w-2xl mx-auto px-4 @sm-screen:px-6 @md-screen:px-10')}>
              {actions}
            </div>
          </div>
        )}
        {plg && (
          <PoweredByFooter
            className={actions && 'bg-footer-background'}
            {...plg}
          />
        )}
      </div>
    </div>
  );
};
export default Main;
